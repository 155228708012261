import { render, staticRenderFns } from "./ChatModal.vue?vue&type=template&id=989ccb3c&scoped=true"
import script from "./ChatModal.vue?vue&type=script&lang=js"
export * from "./ChatModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "989ccb3c",
  null
  
)

export default component.exports