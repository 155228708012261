<template>
  <div class="container-fluid h-100">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <button class="btn" @click="toggleGlobalHideEnded">
              <font-awesome-icon
                title="Hide All Ended Routes"
                icon="eye"
                v-show="!globalHideEnded"
              />
              <font-awesome-icon
                title="Show All Ended Routes"
                icon="eye-slash"
                v-show="globalHideEnded"
              />
            </button>
            &nbsp; Driver Routes for
            <select v-model="workDay" :options="availableWorkDays">
              <option v-for="d in availableWorkDays" :key="d">{{ d }}</option>
            </select>
            &nbsp;
            <button
              class="btn"
              @click="downloadReport"
              title="Download Driver Report"
            >
              <font-awesome-icon icon="file-download" />
            </button>
            &nbsp; Filter Driver
            <input type="text" class="text-uppercase" v-model="driverFilter" />
            <button
              :disabled="driverFilter == null || driverFilter.length == 0"
              @click="clearDriverFilter"
              class="btn"
            >
              <font-awesome-icon icon="window-close" />
            </button>
          </div>
          <div class="col">
            <div class="float-right">
              <select :disabled="readOnly" v-model="driverToBeginRoutes">
                <option :value="null" disabled>Add Driver Work Day</option>
                <option
                  v-for="d in driversWithoutWorkDay"
                  :value="d"
                  :key="d.id"
                >
                  {{ d.firstName }} {{ d.lastName }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <div class="card">
      <div
        class="card-body"
        v-bind:style="{ 'max-height': contentHeight + 'px' }"
        style="overflow-y: auto; padding: 0px"
      >
        <transition-group name="fade-left">
          <template v-for="wd in sortedWorkDays">
            <WorkDayPanel class="row-striped" :workDay="wd" :key="wd.id" />
          </template>
        </transition-group>
      </div>
    </div>
    <LoadingSplash />
    <LadingShipmentModal />
    <ChatModal />
  </div>
</template>

<script>
import LoadingSplash from "../LoadingSplash.vue";
import LadingShipmentModal from "./LadingShipmentModal.vue";
import ChatModal from "../common/ChatModal.vue";
import { mapGetters, mapActions } from "vuex";
import messaging from "../../messaging";
import dateutil from "../../dateutil";
import WorkDayPanel from "./WorkDayPanel.vue";
import reportsapi from "../../api/reportsapi";
import chat from "../../chat";

export default {
  components: {
    LoadingSplash,
    WorkDayPanel,
    LadingShipmentModal,
    ChatModal,
  },
  data() {
    return {
      workDay: null,
      windowHeight: window.innerHeight || 0,
      driverToBeginRoutes: null,
      driverFilter: null,
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage", "loading"]),
    ...mapGetters("routes", [
      "getDrivers",
      "driversWithWorkDay",
      "driversWithoutWorkDay",
      "workDays",
      "routes",
      "routesForDriver",
      "readOnly",
      "globalHideEnded",
    ]),
    contentHeight() {
      return this.windowHeight - 180 || 0;
    },
    availableWorkDays() {
      let d = [];
      for (let i = 0; i < 45; i++) {
        let tmp = dateutil.localNow().subtract(i, "days").format("YYYY-MM-DD");
        d.push(tmp);
      }
      return d;
    },
    filteredWorkDays() {
      let r = this.workDays;
      if (this.driverFilter != null && this.driverFilter.length > 0) {
        r = this.workDays.filter((wd) =>
          wd.driver.firstName
            .toLowerCase()
            .startsWith(this.driverFilter.toLowerCase()),
        );
      }
      return r;
    },
    sortedWorkDays() {
      // eslint-disable-next-line
      return this.filteredWorkDays.sort((wd1, wd2) => {
        let s = 0;
        let wd1Routes = this.routesForDriver(wd1.driver);
        let wd2Routes = this.routesForDriver(wd2.driver);
        if (wd1Routes != null && wd2Routes != null) {
          let wd1LastSort =
            wd1Routes.length == 0
              ? 0
              : wd1Routes[wd1Routes.length - 1].endTime != null
                ? 1
                : 0;
          let wd2LastSort =
            wd2Routes.length == 0
              ? 0
              : wd2Routes[wd2Routes.length - 1].endTime != null
                ? 1
                : 0;
          s = wd1LastSort - wd2LastSort;
        }
        return s;
      });
    },
  },
  watch: {
    driverToBeginRoutes(newVal) {
      if (newVal != null) {
        this.beginWorkDayForDriver(newVal).then(
          () => (this.driverToBeginRoutes = null),
        );
      }
    },
    workDay(newVal) {
      if (newVal != null) {
        this.setWorkDay({ workDay: newVal, driver: null });
      }
    },
  },
  methods: {
    ...mapActions("routes", [
      "loadInitialData",
      "clearData",
      "handleDriverWorkDayRouteMutation",
      "handleDriverWorkDayMutation",
      "handleDriverMutation",
      "handleTrailerMutations",
      "beginWorkDayForDriver",
      "setWorkDay",
      "loadLookups",
      "toggleGlobalHideEnded",
      "setDriver",
    ]),
    clearDriverFilter() {
      this.driverFilter = null;
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    downloadReport() {
      window.open(
        reportsapi.getReportLink("DriverDispatchRoutes", ".xlsx", {
          WordDay: this.workDay,
        }),
        "_new",
        "",
      );
    },
  },
  async mounted() {
    window.addEventListener("resize", this.onResize);
    await this.setDriver(null);
    this.loadLookups().then(() => {
      this.clearData();
      messaging.subscribe(
        "DriverDispatchPage.DriverMutations",
        "/topic/Topic.DriverMutations",
        this.handleDriverMutation,
      );
      messaging.subscribe(
        "DriverDispatchPage.DriverWorkDayMutations",
        "/topic/Topic.DriverWorkDayMutations",
        this.handleDriverWorkDayMutation,
      );
      messaging.subscribe(
        "DriverDispatchPage.DriverWorkDayRouteMutations",
        "/topic/Topic.DriverWorkDayRouteMutations",
        this.handleDriverWorkDayRouteMutation,
      );
      messaging.subscribe(
        "DriverDispatchPage.TrailerMutations",
        "/topic/Topic.TrailerMutations",
        this.handleTrailerMutations,
      );
      messaging.restart();
      this.workDay = dateutil.localNowFormatted("YYYY-MM-DD");
    });
    window.setTimeout(500, () => {
      chat.chatClient.emit("request-chats");
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  destroyed() {
    messaging.unsubscribe("DriverDispatchPage.TrailerMutations");
    messaging.unsubscribe("DriverDispatchPage.DriverWorkDayRouteMutations");
    messaging.unsubscribe("DriverDispatchPage.DriverWorkDayMutations");
    messaging.unsubscribe("DriverDispatchPage.DriverMutations");
  },
};
</script>

<style scoped>
.btn {
  background-color: lightgrey;
}
.btn:hover {
  background-color: lightgreen;
}
.row-striped:nth-of-type(odd) {
  background-color: darkcyan;
}
.row-striped:nth-of-type(even) {
  background-color: lightsalmon;
}
.row-striped {
  border: 3px solid black;
}
</style>
