<template>
  <b-modal id="chat-modal" size="xl" :title="title" hide-footer @hide="onHide">
    <b-row>
      <b-col md="10">
        <b-input v-model="chatInput" />
      </b-col>
      <b-col md="2">
        <b-button @click="sendChat">SEND</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <hr />
      </b-col>
    </b-row>
    <template v-for="(c, idx) in chats">
      <b-row class="row-striped" :key="idx">
        <b-col md="4">
          {{ local(c.ts) }}
        </b-col>
        <b-col md="2">
          {{ trimFrom(c.from) }}
        </b-col>
        <b-col md="6">
          {{ c.chat }}
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import dateutils from "../../dateutil";
import chat from "../../chat";
import { EventBus } from '@/eventbus';

export default {
  data() {
    return {
      chatInput: null,
    };
  },
  computed: {
    ...mapGetters("security", ["userName"]),
    ...mapGetters("chat", ["chats", "workDay", "from"]),
    title() {
      let chatting = this.workDay?.driver?.email == this.from ? "Dispatch" : this.workDay?.driver?.firstName;
      return `Chat with ${chatting}`;
    }
  },
  methods: {
    local(val) {
      return dateutils.getLocalDateTime(val, "YYYY-MM-DD hh:mma z");
    },
    trimFrom(val) {
      return val.substring(0, val.indexOf("@"));
    },
    sendChat() {
      if (this.chatInput && this.chatInput.length > 0) {
        let msg = {
          id: crypto.randomUUID(),
          chat: this.chatInput,
          to: this.workDay.driver.email,
          from: this.userName,
          workDay: this.workDay.id
        };
        chat.chatClient.emit("submit-chat", msg);
        this.chatInput = null;
      }
    },
    onHide() {
      EventBus.$emit("modal-hidden", { modal: "chat-modal", data: { workDay: this.workDay } });
    },
  },
};
</script>

<style scoped></style>
