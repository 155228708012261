import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { routes } from "./routes";
import Vuelidate from "vuelidate";
import Toasted from "vue-toasted";
import Cookies from "vue-cookies";
import VModal from "vue-js-modal";
import VueHotkey from "v-hotkey";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faWindowClose,
  faPlusSquare,
  faSave,
  faPlayCircle,
  faStopCircle,
  faUpload,
  faDownload,
  faTag,
  faEye,
  faEyeSlash,
  faFileDownload,
  faSearch,
  faBell,
  faBellSlash,
  faMinusSquare,
  faCheckSquare,
  faAngleDoubleRight,
  faFileExcel,
  faFilePdf,
  faArrowDown,
  faArrowUp,
  faTrashAlt,
  faEdit,
  faSearchPlus,
  faPlus,
  faMinus,
  faCheck,
  faPrint,
  faBarcode,
  faList,
  faInfoCircle,
  faInfo,
  faQuestion,
  faCheckDouble,
  faUnlock,
  faLock,
  faFileUpload,
  faEnvelope,
  faFilter,
  faFileImport,
  faChevronLeft,
  faChevronRight,
  faBan,
  faCheckCircle,
  faCaretUp,
  faCaretDown,
  faExchangeAlt,
  faSync,
  faExpandAlt,
  faObjectGroup,
  faObjectUngroup,
  faShare,
  faCircle,
  faDollarSign,
  faFileInvoice,
  faArrowLeft,
  faRedo,
  faShareSquare,
  faCopy,
  faWrench,
  faBook,
  faPlaneUp,
  faRotateLeft,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";

library.add(
  faWindowClose,
  faPlusSquare,
  faSave,
  faPlayCircle,
  faStopCircle,
  faUpload,
  faDownload,
  faTag,
  faEye,
  faEyeSlash,
  faFileDownload,
  faSearch,
  faBell,
  faBellSlash,
  faMinusSquare,
  faCheckSquare,
  faAngleDoubleRight,
  faFileExcel,
  faFilePdf,
  faArrowDown,
  faArrowUp,
  faTrashAlt,
  faEdit,
  faSearchPlus,
  faPlus,
  faMinus,
  faCheck,
  faPrint,
  faBarcode,
  faList,
  faInfoCircle,
  faInfo,
  faQuestion,
  faCheckDouble,
  faUnlock,
  faLock,
  faFileUpload,
  faFileDownload,
  faEnvelope,
  faFilter,
  faFileImport,
  faChevronLeft,
  faChevronRight,
  faBan,
  faCheckCircle,
  faCaretUp,
  faCaretDown,
  faExchangeAlt,
  faSync,
  faExpandAlt,
  faObjectGroup,
  faObjectUngroup,
  faShare,
  faCircle,
  faDollarSign,
  faFileInvoice,
  faArrowLeft,
  faRedo,
  faShareSquare,
  faCopy,
  faWrench,
  faBook,
  faPlaneUp,
  faRotateLeft,
  faComment
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Toasted, { duration: 5000 });
Vue.use(Cookies);
Vue.use(VModal, { dynamic: true, injectModalsContainer: true });
Vue.use(VueHotkey);

Vue.$cookies.config("30d");

Vue.config.productionTip = false;

const DateTimePlugin = {
  // eslint-disable-next-line no-unused-vars
  install(Vue) {},
};

const router = new VueRouter({
  routes, // short for `routes: routes`
});

const routeHelper = {
  matchesRole: async function (validRoles) {
    let matches = false;
    if (validRoles != null) {
      if (validRoles.length == 0) {
        matches = true;
      } else {
        for (var ctr = 0; ctr < validRoles.length; ctr++) {
          let r = validRoles[ctr];
          matches |= await store.dispatch("security/inRole", r);
        }
      }
    }
    return matches;
  },
  performAuth: async function (to, from, next, validRoles) {
    await store.dispatch("security/refreshSecurity");
    //console.log("route after refreshsecurity");
    let loggedIn = await store.dispatch("security/checkLoggedIn").then((r) => {
      return r;
    });
    //console.log("route after checkLoggedIn");
    if (loggedIn) {
      let m = await this.matchesRole(validRoles);
      if (m) {
        next();
      } else {
        alert(`You don't have a valid role for ${to.path}`);
        next("/");
      }
    } else {
      await store.dispatch("security/signIn");
      loggedIn = await store.dispatch("security/checkLoggedIn").then((r) => {
        return r;
      });
      if (loggedIn) {
        let m = await this.matchesRole(validRoles);
        if (m) {
          next();
        } else {
          alert(`You don't have a valid role for ${to.path}`);
          next("/");
        }
      } else {
        console.log("couldn't login or something");
        alert(
          "An unknown or unhandled login error has occured, you will be directed to the home page",
        );
        window.setTimeout(() => next("/"), 1000);
      }
    }
  },
};

router.beforeEach((to, from, next) => {
  let hasRoles = to.matched.some((record) => "validRoles" in record.meta);
  if (hasRoles) {
    let validRoles = to.meta.validRoles;
    if (validRoles != null) {
      routeHelper.performAuth(to, from, next, validRoles);
    } else {
      next(); // make sure to always call next()!
    }
  } else {
    next();
  }
});

Vue.use(DateTimePlugin);
Vue.use(VueRouter);
Vue.use(Vuelidate);

const app = new Vue({
  store,
  router,
  render: (h) => {
    return h(App);
  },
});
store.$app = app;
app.$mount("#app");
